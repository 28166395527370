import { BREAKPOINTS, Button, COLORS, ComponentL, ComponentM, ComponentTextStyle, HorizontalCard, LoadingIndicator, PageWidth, Paginator, SearchBar, Size, SystemIcons } from "@laerdal/life-react-components";
import React, { useEffect } from "react";
import { useState } from "react";
import {  useLocation } from "react-router";
import styled from "styled-components";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import ArticleApi from "../../services/api/ArticleApi";
import {useDebouncedValue} from 'rooks';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import posthog from 'posthog-js';


const HeaderContainer = styled.div`
  ${BREAKPOINTS.LARGE}{
    height: 382.845px;
  }
  height: 250px;

  display: flex;
  position: relative;
  
  background: url('assets/LandingHeaderBackground.png') lightgray;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  width: 100%;
  flex-shrink: 0;
`;

const TopFrame = styled(PageWidth)`
  ${BREAKPOINTS.LARGE}{
    width: 1024px;
    height: 126px;
    margin: 32px auto 32px;  
  }
  flex-grow: 1;  

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  
  flex-shrink: 0;
`;

const DesktopH3 = styled.div`
  font-size: 20px;
  ${BREAKPOINTS.LARGE}{
    font-size: 40px;
  }

  color: ${COLORS.white};
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
`;

const DesktopParagraph = styled.div`
  font-size: 12px;
  ${BREAKPOINTS.LARGE}{
    font-size: 20px;
  }

  color: ${COLORS.neutral_20};
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
`;
 
const GrayGradient = styled.div`
  z-index: 0;
  position: absolute;
  width:100%;
  height:100%;
`;

const GrayGradientTop = styled.div`
  height:50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 69.5%, rgba(0, 0, 0, 0.50) 100%);
`;

const GrayGradientBottom = styled.div`
  height:50%;
  background: var(--overlay-black-50, rgba(0, 0, 0, 0.50));
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0 0 0;
`;

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;

  li{
    width:40px !important;
  }
`;

const ArticleContainers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
  margin: 50px 0 0 0;
`;

const ArticlePage = styled.div`
  margin: 0 0 50px 0;
`;

const NoResults = styled.div`
  border-radius: 8px;
  background: ${COLORS.neutral_20};
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const ArticleSearchPage = () => {
  let query = useQuery();

  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState<string>(query.get("query") ?? '');

  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [articlesLoading, setArticlesLoading] = useState<boolean>(true);
  const [articles, setArticles] = useState<KnowledgeArticleListItemDto[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [searchTermDebounce] = useDebouncedValue(searchTerm, 500);

  const [abortController, setAbortController] = useState(new AbortController());

  useEffect(() => {
    // @ts-ignore
    posthog.capture('ArticleSearchPage Search',{
      searchTerm: searchTermDebounce
    });

    const routeSearchTerm = searchTerm ? `?query=${searchTerm}` : '';
    navigate(`/articles${routeSearchTerm}`);

    setShowPagination(false);
    setPage(1);
  }, [searchTermDebounce]);

  const setPage = (page: number) => {
    // @ts-ignore
    window.scrollTo({top: 0, behavior: "instant"});
    setArticles([])
    setArticlesLoading(true);
    
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    const arrayIndex = page - 1;
    ArticleApi.GetKnowledgeArticles(20, arrayIndex, searchTermDebounce, 'all', newAbortController.signal)
      .then((data) => {
        setArticles(data.items);
        setCurrentPage(page);
        setPageCount(Math.floor(data.totalCount / 20) + 1);
        setShowPagination(true)

        if(data.items.length == 0)
        {
          // @ts-ignore
          posthog.capture('ArticleSearchPage SearchNoResults',{
            searchTerm: searchTerm,
            page: page
          });
        }
        setArticlesLoading(false)
      })
      .catch(error => {
        if(!axios.isCancel(error)) {
          setArticlesLoading(false)
        }
      })
  }

  return (
    <>
      <HeaderContainer>
        <GrayGradient>
          <GrayGradientTop></GrayGradientTop>
          <GrayGradientBottom></GrayGradientBottom>
        </GrayGradient>
        <TopFrame useMaxWidth={true} maxWidth={1080}>
          <DesktopH3>Welcome to Laerdal Support</DesktopH3>
          <DesktopParagraph>What do you need help with?</DesktopParagraph>
          <SearchBar
            id="DemoSearchBarSmall"
            placeholder="Type a question or search by keyword"
            searchTerm={searchTerm}
            enterSearch={() => {
              
            }}
            setSearchTerm={(a)=>{ setSearchTerm(a)}}
            
            removeSearch={() => {
              setSearchTerm('')
            }}
            size={Size.Small}
          />
        </TopFrame>
      </HeaderContainer>
      <ArticlePage>
        <PageWidth useMaxWidth={true} maxWidth={1080}>
          <SearchContainer>
            {articlesLoading && <LoadingIndicator />}

            { !articlesLoading &&
              <ArticleContainers key="newToSimCpature">
                {
                  articles.map((item, index )=>
                  (
                    <HorizontalCard 
                      key={`article-${item.articleNumber}`} 
                      title={item.title} 
                      description= {item.description} 
                      tags={[{
                        label: item.readTime + " min" ,
                        icon: <SystemIcons.Time />
                      }]}
                      action={() => { 
                        // @ts-ignore
                        posthog.capture('ArticleSearchPage OpenArticle',{
                          searchTerm: searchTerm,
                          relatedArticle: item.articleNumber,
                          rank: index,
                          page: currentPage
                        });
                        navigate(`/articles/${item.articleNumber}`) }
                      } />
                  ))
                }
              </ArticleContainers>
            }

            {!articlesLoading && articles.length == 0 && 
              <NoResults>
                <NoResultsContainer>
                  <ComponentL textStyle={ComponentTextStyle.Bold}>The search did not return any results</ComponentL>
                  <ComponentM>There are no results for {searchTerm}</ComponentM>
                </NoResultsContainer>
                
                <NoResultsContainer>
                  <ComponentL textStyle={ComponentTextStyle.Bold}>Search tips</ComponentL>
                  <ComponentM>&#9679; Try searching by product type or SKU number</ComponentM>
                  <ComponentM>&#9679; Check the spelling or try other keywords</ComponentM>
                </NoResultsContainer>
                
                <NoResultsContainer>
                  <ComponentL textStyle={ComponentTextStyle.Bold}>Need help?</ComponentL>
                  <ComponentM>Feel free to reach out if you are experiencing any difficulties. </ComponentM>
                </NoResultsContainer>

                <Button 
                  variant="secondary"
                  onClick={() => {
                    window.location.href = 'https://laerdal.com/support/customer-service/contact-us/'
                  }} >
                  Contact us
                </Button>
              </NoResults>
            }

            {showPagination && articles.length != 0 && 
              <PaginatorContainer>
                <Paginator 

                  pageCount={pageCount}
                  currentPage={currentPage}
                  baseUrl="/"
                  onPageChange={(p) => {
                              // @ts-ignore
                              posthog.capture('ArticleSearchPage PaginationChange',{
                                currentPage: currentPage,
                                newPage: p
                              });
                              setPage(p)
                              return true;
                            }}
                  />
              </PaginatorContainer>
            }
          </SearchContainer>

        </PageWidth>
      </ArticlePage> 
    </>);
};

export default ArticleSearchPage;