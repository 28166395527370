import { BREAKPOINTS, Breadcrumb, Button, COLORS, HorizontalCard, HyperLink, LoadingIndicator, PageWidth, Size, SystemIcons, ToastColor, ToastPosition, useToast } from "@laerdal/life-react-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import HelpdeskModalDialog from "../../commonComponents/HelpdeskModalDialog";
import { ArticleFeedbackDto } from "../../model/dto/articleFeedbackDto";
import { KnowledgeArticleDto } from "../../model/dto/knowledgeArticleDto";
import ArticleApi from "../../services/api/ArticleApi";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import ProductApi from "../../services/api/ProductApi";
import posthog from 'posthog-js';

const Containter = styled.div`
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }

  flex-direction: column;
  width:100%;
  display: flex;
  column-gap: 80px;
`;

const FirstColumn = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column; 
  flex: 0 0 450px;
  flex-direction: column;
  flex: 0 0 480px;
`;

const Feedback = styled.div`
  display: flex;
  flex-direction: column;
  padding:30px 40px;
  color: ${COLORS.neutral_20};
  background: ${COLORS.primary_800};
  border-radius: 8px;
  margin: 32px 0 0 0 ;
  row-gap: 12px;
`;

const FeedbackWrapper = styled.div`
  position: sticky;
  top: 50px;
`;

const ArticleFeedbackContainer = styled.div`
  margin: 20px 0 0 0;
  border-radius: 10px;
  padding: 24px 24px 38px 24px;
  background-color: ${COLORS.primary_800};
  font-family: 'Lato';
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ArticleHtmlContainer = styled.div`
  img {
    max-width: 100%;
  }
`;

const ArticlePageContainer = styled.div`
  margin: 0 0 50px 0;
`;

const RelatedArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between; 
  margin: 40px 0 0 0;
`;

const DesktopH6 = styled.div`
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 30px */
  margin-bottom:8px;
`;

const LinkWrapper = styled.div`
  margin:  0 0 16px 0;
`;

const FeedBackHeaderWrapper = styled.div`
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 24px;
  color: ${COLORS.white}
`;

const FeedBackButtonContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const ArticlePage = () => {
  const { product, category, articleNumber, title } = useParams<{ product: string, category:string, articleNumber: string, title:string }>();
  
  const navigate = useNavigate()
  const location = useLocation();
  const [article, setArticle] = useState<KnowledgeArticleDto | undefined>(undefined);
  const [loadingArticle, setLoadingArticle] = useState<boolean>(true);
  const [articles, setArticles] = useState<KnowledgeArticleListItemDto[]>([]);
  const [articlesLoading, setArticlesLoading] = useState<boolean>(true);
  
  const [modalCreateCaseVisible, setModalCreateCaseVisible] = useState<boolean>(false);

  const [helpdeskPhoneNumber, setHelpdeskPhoneNumber] = useState<string | undefined>(undefined);
  const [helpdeskPhoneNumberLoading, setHelpdeskPhoneNumberLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onShowModalChange = () => {
    setShowModal(!showModal);
  }

  const toast = useToast();

  const [rate, setRate] = useState<boolean | undefined>(undefined);

 

  useEffect(() => {
    setArticle(undefined);
    setArticles([]);
    setLoadingArticle(true);
    setArticlesLoading(true);

    ArticleApi.GetKnowledgeArticle(articleNumber ?? '', title)
      .then((data) => {
        data.publicContent = data.publicContent ? data.publicContent.replaceAll("{{currentURL}}", location.pathname) : data.publicContent;
        setArticle(data);
        setLoadingArticle(false);
        posthog.capture('ArticleView',{
          currentArticle: articleNumber,
          version: 'web'
        });

        ArticleApi.GetKnowledgeArticles(6, 0, data.title, '')
          .then((data) => {
            setArticles(data.items.filter(x => x.articleNumber != articleNumber));
            setArticlesLoading(false);
          });
        });
    }, [product, articleNumber]);

  return (
    <>
      <ArticlePageContainer>
        <PageWidth useMaxWidth={true} maxWidth={1600}>

          <Containter>

            <HelpdeskModalDialog phoneNumber={helpdeskPhoneNumber} showModal={showModal} isLoading={helpdeskPhoneNumberLoading} onShowModalChange={onShowModalChange} />

            <FirstColumn>
              {loadingArticle && <LoadingIndicator />}
            
              {article && <h1>{article.title}</h1>}
              {article && <ArticleHtmlContainer dangerouslySetInnerHTML={{__html: article.publicContent ?? ''}}></ArticleHtmlContainer>}
            </FirstColumn>

            <SecondColumn>
            <RelatedArticlesContainer>
                <DesktopH6>Related articles</DesktopH6>
                
                {articlesLoading && <LoadingIndicator />}

                {!articlesLoading &&
                      articles.map(item =>
                      (
                        <LinkWrapper key={`article-${item.articleNumber}`} >
                          <HyperLink  
                            onClick={(e)=> {
                              e.preventDefault()
                              // @ts-ignore
                              posthog.capture('ArticlePage OpenRelatedArticle',{
                                currentArticle: articleNumber,
                                relatedArticle: item.articleNumber,
                              });
                              navigate(`/articles/${item.articleNumber}`)
                            }}
                            id="HyperLinkDefault" 
                            variant="default" 
                            href={`#`}  >

                            {item.title}
                          </HyperLink>
                       </LinkWrapper>
                      ))
                }
                {!articlesLoading && articles.length == 0 && "No related articles"}
              </RelatedArticlesContainer>
            </SecondColumn>
          </Containter>
        </PageWidth>
      </ArticlePageContainer>

      
    </>);
};

export default ArticlePage;
