import { GlobalNavigationBar } from '@laerdal/life-react-components';

const Header = () => {
    const navigation = {
        items: [
          // {
          //   label: 'Home',
          //   to: '/',
          //   exact: true
          // },          
          // {
          //   label: 'Articles',
          //   to: '/articles',
          // }
        ]
      } as any;

    return (
        <GlobalNavigationBar
            isAuthenticated={true}
            name="Support"
            useMaxWidth={true}
            desktop={navigation}

        />
    );
}

export default Header;