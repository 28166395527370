import { BREAKPOINTS, Button, Card, COLORS, ComponentL, ComponentTextStyle, ComponentXL, ContentIcon, ContentIcons, HorizontalCard, PageWidth, SearchBar, Size, SystemIcons, Tile } from "@laerdal/life-react-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import posthog from 'posthog-js';

import styled from "styled-components";

const HeaderContainer = styled.div`
  ${BREAKPOINTS.LARGE}{
    height: 382.845px;
  }
  height: 250px;

  display: flex;
  position: relative;
  
  background: url('assets/LandingHeaderBackground.png') lightgray;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  width: 100%;
  flex-shrink: 0;
`;

const TopFrame = styled(PageWidth)`
  ${BREAKPOINTS.LARGE}{
    width: 1024px;
    height: 126px;
    margin: 32px auto 32px;  
  }
  flex-grow: 1;  

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  
  flex-shrink: 0;
`;


const Contact = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  background: ${COLORS.neutral_20};
`;

const DesktopH3 = styled.div`
  font-size: 20px;
  ${BREAKPOINTS.LARGE}{
    font-size: 40px;
  }

  color: ${COLORS.white};
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
`;

const DesktopParagraph = styled.div`
  font-size: 12px;
  ${BREAKPOINTS.LARGE}{
    font-size: 20px;
  }

  color: ${COLORS.neutral_20};
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
`;
 
const GrayGradient = styled.div`
  z-index: 0;
  position: absolute;
  width:100%;
  height:100%;
`;

const GrayGradientTop = styled.div`
  height:50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 69.5%, rgba(0, 0, 0, 0.50) 100%);
`;

const GrayGradientBottom = styled.div`
  height:50%;
  background: var(--overlay-black-50, rgba(0, 0, 0, 0.50));
`;


const Topic = styled.div`
  border-radius: 8px;
  background: ${COLORS.neutral_50};

  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const TopicSelection = styled.div`
  ${BREAKPOINTS.MEDIUM}{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  ${BREAKPOINTS.LARGE}{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  
  align-items: center;
  display: flex;
  gap: 16px;
  align-self: stretch;
  flex-direction: column;
  align-items: stretch;
`;

const ArticleSection = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  flex-direction: column;
`;

const Container = styled.div`
  align-items: stretch;
  align-self: stretch;

  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
`;

const ContainerJustified = styled.div`
  //align-self: stretch;

  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;

const ContainerBottomButton = styled(Button)`
  ${BREAKPOINTS.MEDIUM}{
    align-self: start;
  }
  ${BREAKPOINTS.LARGE}{
    align-self: stretch;
  }
  align-self: stretch;
`;

const KnowledgeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const KnowledgeFrame = styled.div`
  ${BREAKPOINTS.LARGE}{
    height: 423px;
  }
  height: 340px;

  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
`;


const HorizontalCardFullWidth = styled(HorizontalCard)`
  width: 100%;
`

const KnowledgeTile = styled(Tile)`
  ${BREAKPOINTS.LARGE}{
    height: 423px;
  }
  height: 320px;

  display: flex;
  padding: 26px 12px 22px 12px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  img {
    ${BREAKPOINTS.LARGE}{
      width:380px;
    }
    width:250px;
  }
`;

const Page = styled(PageWidth)`
  display: flex;
  flex-direction: column;
  gap:32px
`;


const Services = styled.div`
  
`;

const CantFind = styled.div`
  
`;


const LandingPage = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const navigate = useNavigate()

  return (
    <>
      <HeaderContainer>
        <GrayGradient>
          <GrayGradientTop></GrayGradientTop>
          <GrayGradientBottom></GrayGradientBottom>
        </GrayGradient>
        
        <TopFrame 
          useMaxWidth={true} 
          maxWidth={1080}
          >

          <DesktopH3>Welcome to Laerdal Support</DesktopH3>
          <DesktopParagraph>What do you need help with?</DesktopParagraph>
          <SearchBar
            id="DemoSearchBarSmall"
            placeholder="Type a question or search by keyword"
            enterSearch={(e) => {
              // @ts-ignore
              posthog.capture('LandingPage Search',{
                searchTerm: searchTerm
              });
              navigate('articles?query='+searchTerm);
            }}
            setSearchTerm={(term)=>{setSearchTerm(term)}}
            
            removeSearch={() => {
              
            }}
            size={Size.Small}
          />
        </TopFrame>
      </HeaderContainer>
      
      <Page useMaxWidth={true} maxWidth={1080}>
        <Topic>
          <ComponentXL textStyle={ComponentTextStyle.Bold}>Topics</ComponentXL>
          <TopicSelection>
            <Card
              onCardClicked={() => { 
                  window.location.href = 'https://laerdal.my.site.com/HelpCenter/s/topic/0TO0N000000ka8zWAA/products-services';
                  return {};
                }}
              variant="elevated"
              //maxWidth={'221px'}
              topSectionProps={{
                image: {
                  src: "assets/Topic1.png",
                  alt: "Products and services",
                  width: "221px",
                  height: "149px"
                }}
              }
              middleSectionProps={{
                title: "",
                description: "Products and services"
              }}/>
              
            <Card
              onCardClicked={() => { 
                window.location.href = 'https://laerdal.com/support/ordering-shipping/';
                  return {};
                }}
              variant="elevated"
              //maxWidth={'221px'}
              topSectionProps={{
                image: {
                  src: "assets/Topic2.png",
                  alt: "Orders and shipping",
                  width: "221px",
                  height: "149px"
                }}
              }
              middleSectionProps={{
                title: "",
                description: "Orders and shipping"
              }}/>
              
              
            <Card
              onCardClicked={() => { 
                window.location.href = 'https://connect.laerdal.com/';
                  return {};
                }}
              variant="elevated"
              //maxWidth={'221px'}
              topSectionProps={{
                image: {
                  src: "assets/Topic3.png",
                  alt: "Laerdal Connect",
                  width: "221px",
                  height: "149px"
                }}
              }
              middleSectionProps={{
                title: "",
                description: "Laerdal Connect"
              }}/>
              
            <Card
              onCardClicked={() => { 
                window.location.href = 'https://laerdal.com/support/ordering-shipping/product-returns/';
                  return {};
                }}
              variant="elevated"
              //maxWidth={'221px'}
              topSectionProps={{
                image: {
                  src: "assets/Topic4.png",
                  alt: "Product Returns",
                  width: "221px",
                  height: "149px"
                }}
              }
              middleSectionProps={{
                title: "",
                description: "Product Returns"
              }}/>
          </TopicSelection>
        </Topic>

        <ArticleSection>
          <Container>

            <HorizontalCard
              icon={<ContentIcons.Video />}
              style={{width:"100%"}}
              variant="elevated"
              title="How-to videos"
              description="Find out how to setup, use and maintain your Laerdal product with our how to videos."
              action={() => { window.location.href = 'https://laerdal.com/support/how-to-videos/' }}
              actions={[
                {componentType: 'icon', action: ()=>{ window.location.href = 'https://laerdal.com/support/how-to-videos/' }, icon: <SystemIcons.OpenNewWindow/>, tooltip: {label: 'How-to videos'}}
              ]}/>

            <HorizontalCardFullWidth
              icon={<ContentIcons.Download />}
              variant="elevated"
              title="Product and Software Downloads"
              description="Find your product below to see all available downloads."
              action={() => { window.location.href = 'https://laerdal.com/downloads' }}
              actions={[
                {componentType: 'icon', action: ()=>{ window.location.href = 'https://laerdal.com/downloads' }, icon: <SystemIcons.OpenNewWindow/>, tooltip: {label: 'Product and Software Downloads'}}
              ]}/>

            <HorizontalCard
              icon={<ContentIcons.MoodVeryHappy />}
              variant="elevated"
              title="Laerdal Global Warranty"
              description="For warranty service, contact us or your authorised dealer and review the directions for use beforehand."
              action={() => { window.location.href = 'https://laerdal.com/support/ordering-shipping/laerdal-global-warranty/' }}
              actions={[
                {componentType: 'icon', action: ()=>{ window.location.href = 'https://laerdal.com/support/ordering-shipping/laerdal-global-warranty/' }, icon: <SystemIcons.OpenNewWindow/>, tooltip: {label: 'Laerdal Global Warranty'}}
              ]}/>
              
            <HorizontalCard
              icon={<ContentIcons.ProvidersOrders />}
              variant="elevated"
              title="Terms & Conditions of Sale"
              description="This document outlines the terms and conditions governing the sale of products by Laerdal Medical."
              action={() => { window.location.href = 'https://laerdal.com/support/ordering-shipping/payment-terms/' }}
              actions={[
                {componentType: 'icon', action: ()=>{ window.location.href = 'https://laerdal.com/support/ordering-shipping/payment-terms/' }, icon: <SystemIcons.OpenNewWindow/>, tooltip: {label: 'Terms & Conditions of Sale'}}
              ]}/>

            <HorizontalCard
                icon={ <ContentIcons.Information/>}
                variant="elevated"
                title="General Latex Information"
                description="Latex is a naturally occurring material that originates from the sap of a type of commercial rubber tree."
                action={() => { window.location.href = 'https://laerdal.com/support/customer-service/latex-information/' }}
                actions={[
                  {componentType: 'icon', action: ()=>{ window.location.href = 'https://laerdal.com/support/customer-service/latex-information/' }, icon: <SystemIcons.OpenNewWindow/>, tooltip: {label: 'General Latex Information'}}
              ]}/>

            <ContainerBottomButton 
              variant="secondary" 
              icon={<SystemIcons.Search />} 
              onClick={() => { window.location.href = 'https://laerdal.com/support/' }} >
              View more
            </ContainerBottomButton>
          </Container>

          <ContainerJustified>
              <KnowledgeTitleWrapper>
                <ComponentXL textStyle={ComponentTextStyle.Bold}>KnowledgeHub</ComponentXL>
                <ComponentL>Learn the basics of simulation and how to use your equipment efficiently.</ComponentL>
              </KnowledgeTitleWrapper>

              <KnowledgeFrame>
                <KnowledgeTile >
                  <img src="assets/KnowledgeHub2.png"   alt="" />
                </KnowledgeTile>
              </KnowledgeFrame>
              <ContainerBottomButton 
                
                variant="primary"
                icon={<SystemIcons.OpenNewWindow />} 
                onClick={() => { window.location.href = 'https://laerdal.com/services-and-programs/knowledgehub/' }}>
                Read more
              </ContainerBottomButton>
          </ContainerJustified>
        </ArticleSection>

        <Contact>
          <ComponentXL textStyle={ComponentTextStyle.Bold}>
            Can’t find what you need?
          </ComponentXL>
          <HorizontalCardFullWidth
              icon={<ContentIcons.Note />}
              variant="elevated"
              title="Create a new case"
              description="Ask a question or report an issue."
              action={() => { window.location.href = 'https://laerdal.my.site.com/HelpCenter/s/contactsupport' }}
              actions={[
                {componentType: 'icon', action: ()=>{ window.location.href = 'https://laerdal.my.site.com/HelpCenter/s/contactsupport' }, icon: <SystemIcons.OpenNewWindow/>, tooltip: {label: 'Terms & Conditions of Sale'}}
              ]}/>
        </Contact>
      </Page>
      
    </>);
};

export default LandingPage;