import { COLORS, LoadingIndicator, Modal, ModalDialog, Size } from "@laerdal/life-react-components";
import styled from "styled-components";
import App from "../App";

const ModalDescription = styled.div`
  font-size: 23px;
  font-family: 'Lato';
  margin: 0 0 30px 0;
`;

const ContactsWrapper = styled.div`
  color: ${COLORS.neutral_300};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  margin-bottom: 16px;
`;

const Contacts = styled.div`
  font-size: 20px;
`;

const LaerdalSupportWrapper = styled.div`
  font-size: 14px;
  font-family: 'Lato';
  margin: 3px 0 0 0;
`;

interface HelpdeskModalDialogProps {
  phoneNumber?: string,
  showModal: boolean,
  isLoading: boolean,
  onShowModalChange: () => void
}

const HelpdeskModalDialog = ({phoneNumber, showModal, isLoading, onShowModalChange}: HelpdeskModalDialogProps) => {

    const onContactsModalClose = () => {
      onShowModalChange();
    };

    return (
        <>
            {/* <ModalDialog
              closeModalAndClearInput={() => {}}
              closeAction={() => { onContactsModalClose(); }}
              submitAction={() => { }}
              title={'Call Helpdesk'}
              size={Size.Large}
              isModalOpen={showModal}
              buttons={[
                {
                  text: 'Close',
                  variant: 'tertiary',
                  action: () => { onContactsModalClose(); }
                }
              ]}
              note = "Hours of operation: Mon-Fri 9:00-17:00 EST"
            >
              <>
                <ModalDescription>Contact our support team who will be happy assist you</ModalDescription>
                <ContactsWrapper>
                  <Contacts>
                    {isLoading && <LoadingIndicator />}
                    {!isLoading && phoneNumber}
                  </Contacts>
                  <LaerdalSupportWrapper>
                    Laerdal Support USA
                  </LaerdalSupportWrapper>
                </ContactsWrapper>
                <hr />
              </>
            </ModalDialog> */}
        </>
    );
};

export default HelpdeskModalDialog;