import { SiteFooter, ToastProvider } from '@laerdal/life-react-components';
import { Component, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, useLocation} from 'react-router';
import styled from 'styled-components';
import ArticlePage from './pages/articlePage/ArticlePage';
import ArticleSearchPage from './pages/articleSearchPage/ArticleSearchPage';
import LandingPage from './pages/landingPage/LandingPage';
import EmbededSearchPage from './pages/embededSearchPage/EmbededSearchPage';
import EmbededArticlePage from './pages/embededArticlePage/EmbededArticlePage';
import { Routes } from 'react-router-dom';
import Header from './commonComponents/Header';
import posthog from 'posthog-js';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const FooterContainer = styled.div`
  margin-top: auto;
`;

const TestIframe = styled.iframe`
  height: 95vh;
`;



const App = () => {
  const location = useLocation();

  window.addEventListener("message",
    (event) => {
      if(event.data == 'closingHelpU'){
        // @ts-ignore
        posthog?.capture('helpU closed',{
          $geoip_disable: true,
        });
      }
    },
    false
  );

  useEffect(() => { 
    posthog.capture('$pageview')
  }, [location.pathname]);


  return (
    <>
        <ContentContainer>
        {location.pathname.startsWith('/embeded') == false &&
          <Header/>
        }

        <Routes>
          <Route path='/' element={<LandingPage/>} />
          <Route path='/articles' element={<ArticleSearchPage/>} />
          <Route path='/embeded/search' element={<EmbededSearchPage/>} />
          <Route path='/embeded/articles/:articleNumber' element={<EmbededArticlePage/>} />

          <Route path='/articles/:articleNumber' element={<ArticlePage/>} />
          <Route path='/articles/Knowledge/:title' element={<ArticlePage/>} />
          <Route path='/embeded-articles-peligo/single' element={<TestIframe src='https://laerdalhelputest.blob.core.windows.net/peligo-single/en/index-en.html'></TestIframe>} />
          <Route path='/embeded-articles-peligo/step' element={<TestIframe src='https://laerdalhelputest.blob.core.windows.net/peligo-html/en/index-en.html'></TestIframe>} />
          
        </Routes>


        {location.pathname.startsWith('/embeded') == false &&
          <FooterContainer>
            <SiteFooter 
              id="helpu-footer" 
              toTopText='TOP'
              sitename='Suport'
              copyrightText={`Copyright © ${new Date().getFullYear()} Laerdal Medical. All Rights Reserved.`}
              bottomLinks={[
                  {
                    label: 'Terms of Use',
                    to: 'https://laerdal.com/support/customer-service/terms-of-use/',
                  },
                  {
                    label: 'Privacy Statement',
                    to: 'https://laerdal.com/support/customer-service/privacy-statement/',
                  },
                  {
                    label: 'Accessibility statement',
                    to: 'https://laerdal.com/support/customer-service/accessibility/',
                  },
                  {
                    label: 'Sustainability',
                    to: 'https://laerdal.com/about-us/sustainability/',
                  }
                ]}
              />
          </FooterContainer>
        }
        </ContentContainer>
    </>
  );
}

export default App;